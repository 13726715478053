.rc-time-picker-panel {
  z-index: 9999 !important;
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
}

.rc-time-picker {
  width: 100%;
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  margin-top: 8px;
  margin-bottom: 4px;
}

.rc-time-picker .rc-time-picker-input {
  padding: 10.5px 14px;
  font-size: 1rem;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  border-width: 1px;
  line-height: 1;
  height: auto;
  color: #111;
}

.rc-time-picker .rc-time-picker-clear {
  top: 8px;
  right: 10px;
}

.status-labels {
  min-width: 30%;
}

@media only screen and (max-width: 767px) {
  .status-labels {
    width: 100%;
  }
}

.show-invoice {
  color: rgb(182, 179, 179);
  text-align: center;
  margin-left: 32%;
}

.show-invoice span {
  color: rgb(12, 152, 170);
  cursor: pointer;
}

.error {
  background-color: rgb(211, 78, 78);
  color: white;
  padding: 15px 50px;
}